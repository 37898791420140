body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  overflow-x: hidden;
}

img {
  width: 100%;
}

ul li {
  list-style: none;
}

table {
  border-spacing: 0;
}

body * {
  font-family: "Noto Sans JP", sans-serif;
  color: #1a1a1a;
  line-height: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 500;
}

a {
  color: #1a1a1a;
  text-decoration: none;
  vertical-align: baseline;
}

a:hover, button:hover {
  opacity: 0.8;
}

section, footer {
  margin: 0 auto;
  width: 100%;
  max-width: 2000px;
}

.pc_item {
  display: block;
}
@media screen and (max-width: 750px) {
  .pc_item {
    display: none;
  }
}

.sp_item {
  display: none;
}
@media screen and (max-width: 750px) {
  .sp_item {
    display: block;
  }
}

.lp_wrap {
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .lp_wrap {
    width: 100vw;
    overflow-x: hidden;
  }
}

.inner {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .inner {
    width: 100vw;
  }
}

.no-scroll {
  overflow: hidden;
}

/* ========================================
  header
======================================== */
.header {
  background: #fff;
  height: 80px;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.15));
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  z-index: 10;
}
@media screen and (max-width: 1200px) {
  .header {
    height: 6.67vw;
    filter: drop-shadow(0px 0.42vw 0.42vw rgba(0, 0, 0, 0.15));
  }
}
@media screen and (max-width: 750px) {
  .header {
    top: -100vh;
    height: 100vh;
    filter: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.5s;
  }
  .header.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
.header .header_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
@media screen and (max-width: 1200px) {
  .header .header_wrap {
    height: 6.67vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_wrap {
    display: block;
    height: auto;
  }
}
.header .header_logo {
  width: 220px;
}
@media screen and (max-width: 1200px) {
  .header .header_logo {
    width: 18.33vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_logo {
    width: 44.27vw;
    margin-top: 2.67vw;
    margin-left: 2.67vw;
  }
}
.header .header_box {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .header .header_box {
    display: block;
  }
}
.header .header_list {
  display: flex;
}
@media screen and (max-width: 750px) {
  .header .header_list {
    margin-top: 6.67vw;
    display: block;
  }
}
.header .header_list .header_list_item {
  margin: 0 13px;
}
@media screen and (max-width: 1200px) {
  .header .header_list .header_list_item {
    margin: 0 1.08vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list .header_list_item {
    width: 90.67vw;
    margin: 0 auto;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list .header_list_item:first-of-type a {
    border-top: none;
  }
}
.header .header_list a {
  padding-right: 24px;
  position: relative;
  display: block;
}
@media screen and (max-width: 1200px) {
  .header .header_list a {
    padding-right: 2vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list a {
    padding-right: 3.2vw;
    padding-left: 4.27vw;
    padding-top: 4.4vw;
    padding-bottom: 5.33vw;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
}
.header .header_list a::after {
  content: "";
  display: block;
  width: 13px;
  height: 9px;
  background: url("../../public/images/header_arrow.png") no-repeat center/100%;
  position: absolute;
  right: 3px;
  top: 19px;
}
@media screen and (max-width: 1200px) {
  .header .header_list a::after {
    width: 1.08vw;
    height: 0.75vw;
    right: 0.25vw;
    top: 1.58vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list a::after {
    background: url("../../public/images/ham_arrow.png") no-repeat center/100%;
    width: 3.2vw;
    height: 2.27vw;
    right: 4.67vw;
    top: 10.67vw;
  }
}
.header .header_list .eng {
  color: #638ce0;
  font-size: 10px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 1200px) {
  .header .header_list .eng {
    font-size: 0.83vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list .eng {
    font-size: 2.93vw;
  }
}
.header .header_list .txt {
  margin-top: 4px;
  font-size: 16px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 1200px) {
  .header .header_list .txt {
    margin-top: 0.33vw;
    font-size: 1.33vw;
  }
}
@media screen and (max-width: 750px) {
  .header .header_list .txt {
    margin-top: 1.73vw;
    font-size: 4.27vw;
  }
}
.header .btn {
  display: block;
  background: url("../../public/images/header_btn.png") no-repeat center/100%;
  width: 260px;
  height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  padding-right: 14px;
  margin-left: 22px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 1200px) {
  .header .btn {
    width: 21.67vw;
    height: 4.17vw;
    font-size: 1.5vw;
    line-height: 4.17vw;
    padding-right: 1.17vw;
    margin-left: 1.83vw;
    margin-right: 1.67vw;
  }
}
@media screen and (max-width: 750px) {
  .header .btn {
    background: url("../../public/images/header_btn_sp.png") no-repeat center/100%;
    width: 80vw;
    height: 16vw;
    font-size: 4.53vw;
    line-height: 16vw;
    padding-right: 1.87vw;
    margin: 13.33vw auto 0;
  }
}

/*./header*/
@media screen and (max-width: 750px) {
  .ham_btn {
    position: fixed;
    width: 13.33vw;
    height: 13.33vw;
    top: 2.67vw;
    right: 2.67vw;
    background-color: #1646dd;
    z-index: 9999;
  }
  .ham_btn.active span:nth-of-type(1) {
    transform: translate(-50%, 0%) rotate(45deg);
    top: 6.67vw;
  }
  .ham_btn.active span:nth-of-type(2) {
    opacity: 0;
  }
  .ham_btn.active span:nth-of-type(3) {
    transform: translate(-50%, 0%) rotate(-45deg);
    top: 6.67vw;
  }
  .ham_btn span {
    display: block;
    width: 6.67vw;
    height: 0.53vw;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    transition: all 0.3s;
  }
  .ham_btn span:nth-of-type(1) {
    top: 3.87vw;
  }
  .ham_btn span:nth-of-type(2) {
    top: 6.67vw;
  }
  .ham_btn span:nth-of-type(3) {
    top: 9.6vw;
  }
}

/* ========================================
  fv_area
======================================== */
.fv_area {
  background: url("../../public/images/fv_bg.jpg") top center no-repeat;
  background-size: 2000px 900px;
  height: 900px;
}
@media screen and (max-width: 1200px) {
  .fv_area {
    background-size: 166.67vw 75vw;
    height: 75vw;
  }
}
@media screen and (max-width: 750px) {
  .fv_area {
    background: url("../../public/images/fv_bg_sp.jpg") top center no-repeat;
    background-size: 100vw 157.33vw;
    height: 157.33vw;
  }
}

/*./fv_area*/
/* ========================================
  about_area
======================================== */
.about_area {
  background: url("../../public/images/about_bg.jpg") top center no-repeat;
  background-size: 2000px 1210px;
  height: 1210px;
}
@media screen and (max-width: 1200px) {
  .about_area {
    background-size: 166.67vw 100.83vw;
    height: 100.83vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area {
    background: url("../../public/images/about_bg_sp.jpg") bottom center no-repeat, #e4eff5;
    background-size: 100vw 138.53vw, 100%;
    height: auto;
    padding-bottom: 16vw;
  }
}
.about_area .inner::after {
  content: "";
  display: block;
  width: 341px;
  height: 287px;
  background: url("../../public/images/about_deco.png") no-repeat center/100%;
  position: absolute;
  right: -171px;
  top: 156px;
}
@media screen and (max-width: 1200px) {
  .about_area .inner::after {
    width: 28.42vw;
    height: 23.92vw;
    right: -14.25vw;
    top: 13vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .inner::after {
    width: 29.2vw;
    height: 24.53vw;
    right: 5.33vw;
    top: 66.67vw;
  }
}
.about_area .about_txt {
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.04em;
  margin-top: 30px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_txt {
    font-size: 1.5vw;
    line-height: 2.67vw;
    margin-top: 2.5vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_txt {
    font-size: 3.73vw;
    line-height: 6.67vw;
    margin-top: 6vw;
  }
}
.about_area .about_txt strong {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #1646dd;
}
@media screen and (max-width: 1200px) {
  .about_area .about_txt strong {
    font-size: 1.5vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_txt strong {
    font-size: 3.73vw;
    line-height: 6.67vw;
  }
}
.about_area .about_list {
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list {
    margin-top: 4.5vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list {
    display: block;
    margin-top: 8vw;
  }
}
.about_area .about_list_item {
  width: 380px;
  height: 380px;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.1));
  padding-top: 232px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list_item {
    width: 31.67vw;
    height: 31.67vw;
    filter: drop-shadow(0.83vw 0.83vw 0.83vw rgba(0, 0, 0, 0.1));
    padding-top: 19.33vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item {
    display: block;
    margin: 0 auto;
    width: 73.07vw;
    height: 73.07vw;
    filter: drop-shadow(1.33vw 1.33vw 1.33vw rgba(0, 0, 0, 0.1));
    padding-top: 44vw;
  }
}
.about_area .about_list_item::after {
  content: "";
  display: block;
  width: 94px;
  height: 94px;
  background: url("../../public/images/about_check.png") no-repeat center/100%;
  position: absolute;
  left: 7px;
  top: 7px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list_item::after {
    width: 7.83vw;
    height: 7.83vw;
    left: 0.58vw;
    top: 0.58vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item::after {
    width: 17.73vw;
    height: 17.73vw;
    left: 1.33vw;
    top: 1.87vw;
  }
}
.about_area .about_list_item:nth-of-type(1) {
  background: url("../../public/images/about_list_01.png") no-repeat center/100%;
}
.about_area .about_list_item:nth-of-type(2) {
  background: url("../../public/images/about_list_02.png") no-repeat center/100%;
  margin-top: 40px;
  padding-top: 262px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list_item:nth-of-type(2) {
    margin-top: 3.33vw;
    padding-top: 21.83vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item:nth-of-type(2) {
    margin-top: 7.73vw;
    padding-top: 50.67vw;
  }
}
.about_area .about_list_item:nth-of-type(3) {
  background: url("../../public/images/about_list_03.png") no-repeat center/100%;
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item:nth-of-type(3) {
    margin-top: 7.73vw;
  }
}
.about_area .about_list_item .txt {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list_item .txt {
    font-size: 2vw;
    line-height: 2.83vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item .txt {
    font-size: 4.27vw;
    line-height: 6.67vw;
  }
}
.about_area .about_list_item .txt strong {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: #1646dd;
}
@media screen and (max-width: 1200px) {
  .about_area .about_list_item .txt strong {
    font-size: 2vw;
    line-height: 2.83vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_list_item .txt strong {
    font-size: 4.27vw;
    line-height: 6.67vw;
  }
}
.about_area .about_read {
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 38px;
  line-height: 64px;
  letter-spacing: 0.02em;
  margin-top: 86px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_read {
    font-size: 3.17vw;
    line-height: 5.33vw;
    margin-top: 7.17vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_read {
    font-size: 5.87vw;
    line-height: 3.67vw;
    margin-top: 15.67vw;
  }
}
.about_area .about_read span {
  color: #fff;
  font-weight: 700;
  font-size: 80%;
  line-height: 64px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .about_area .about_read span {
    line-height: 5.33vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_read span {
    line-height: 6.17vw;
  }
}
.about_area .about_read strong {
  background-color: #fff;
  color: #1646dd;
  font-weight: 700;
  font-size: 130%;
  line-height: 64px;
  height: 64px;
  display: inline-block;
  letter-spacing: 0.02em;
  margin: 0 5px;
  padding: 0 5px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_read strong {
    line-height: 5.33vw;
    height: 5.33vw;
    margin: 0 0.42vw;
    padding: 0 0.42vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_read strong {
    line-height: 9.87vw;
    height: 9.87vw;
    margin: 0 0.67vw 4vw;
    padding: 0 0.67vw;
  }
}
.about_area .about_read::after {
  content: "";
  display: block;
  width: 6px;
  height: 51px;
  background: url("../../public/images/about_dot.png") no-repeat center/100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: -57px;
}
@media screen and (max-width: 1200px) {
  .about_area .about_read::after {
    width: 0.5vw;
    height: 4.25vw;
    top: -4.75vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_read::after {
    width: 0.8vw;
    height: 6.8vw;
    top: -11.61vw;
  }
}
.about_area .about_detail {
  position: relative;
  margin: 55px auto 0;
  padding-left: 180px;
  width: 1080px;
  height: 140px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  padding-top: 40px;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.1));
}
@media screen and (max-width: 1200px) {
  .about_area .about_detail {
    margin: 4.58vw auto 0;
    padding-left: 15vw;
    width: 90vw;
    height: 11.67vw;
    border-radius: 0.83vw;
    padding-top: 3.33vw;
    filter: drop-shadow(0.83vw 0.83vw 0.83vw rgba(0, 0, 0, 0.1));
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_detail {
    margin: 10vw auto 0;
    padding-left: 0vw;
    width: 89.33vw;
    height: 44vw;
    border-radius: 1.33vw;
    padding-top: 5.73vw;
    filter: drop-shadow(1.33vw 1.33vw 1.33vw rgba(0, 0, 0, 0.1));
    display: block;
  }
}
.about_area .about_detail::after {
  content: "";
  display: block;
  width: 158px;
  height: 213px;
  background: url("../../public/images/about_detail_deco.png") no-repeat center/100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media screen and (max-width: 1200px) {
  .about_area .about_detail::after {
    width: 13.17vw;
    height: 17.75vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_detail::after {
    background: url("../../public/images/about_detail_deco_sp.png") no-repeat center/100%;
    width: 15.07vw;
    height: 19.47vw;
    top: -7.6vw;
    bottom: auto;
  }
}
.about_area .about_detail_wrap {
  display: flex;
}
@media screen and (max-width: 750px) {
  .about_area .about_detail_wrap {
    display: block;
  }
}
.about_area .about_detail_tit {
  color: #1646dd;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 1200px) {
  .about_area .about_detail_tit {
    font-size: 2.33vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_detail_tit {
    text-align: right;
    font-size: 4.27vw;
    padding-right: 5.73vw;
  }
}
.about_area .about_detail_txt {
  margin-top: 10px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .about_area .about_detail_txt {
    margin-top: 0.83vw;
    font-size: 1.5vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .about_detail_txt {
    text-align: center;
    margin-top: 1.73vw;
    font-size: 3.47vw;
    line-height: 6.67vw;
  }
}
.about_area .btn {
  display: block;
  background: url("../../public/images/about_btn.png") no-repeat center/100%;
  width: 360px;
  height: 60px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 60px;
  text-align: center;
  padding-right: 20px;
  margin-left: 37px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .about_area .btn {
    width: 30vw;
    height: 5vw;
    font-size: 1.83vw;
    line-height: 5vw;
    padding-right: 1.67vw;
    margin-left: 3.08vw;
  }
}
@media screen and (max-width: 750px) {
  .about_area .btn {
    background: url("../../public/images/about_btn_sp.png") no-repeat center/100%;
    width: 77.33vw;
    height: 16.13vw;
    font-size: 4vw;
    line-height: 16.13vw;
    padding-right: 2.67vw;
    margin: 2.4vw auto 0;
  }
}

/*./about_area*/
/* ========================================
  features_area
======================================== */
.features_area {
  background: url("../../public/images/features_bg.jpg") top center no-repeat;
  background-size: 2000px 1779px;
  height: 1779px;
}
@media screen and (max-width: 1200px) {
  .features_area {
    background-size: 166.67vw 148.25vw;
    height: 148.25vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area {
    background: url("../../public/images/features_bg_sp.jpg") top center no-repeat;
    background-size: 100vw 519.07vw;
    height: 519.07vw;
  }
}
.features_area .inner_01::after {
  content: "";
  display: block;
  width: 374px;
  height: 368px;
  background: url("../../public/images/features_deco.png") no-repeat center/100%;
  position: absolute;
  right: -150px;
  top: -62px;
}
@media screen and (max-width: 1200px) {
  .features_area .inner_01::after {
    width: 31.17vw;
    height: 30.67vw;
    right: -12.5vw;
    top: -5.17vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .inner_01::after {
    background: url("../../public/images/features_deco_sp.png") no-repeat center/100%;
    width: 100vw;
    height: 25.33vw;
    right: 0vw;
    top: -5.34vw;
  }
}
.features_area .features_list {
  margin-top: 47px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list {
    margin-top: 3.92vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list {
    margin-top: 0vw;
  }
}
.features_area .features_list .features_list_item {
  height: 440px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item {
    height: 36.67vw;
    margin-bottom: 3.33vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item {
    margin-bottom: 10vw;
  }
}
.features_area .features_list .features_list_item:nth-of-type(1) {
  background: url("../../public/images/features_01.png") top center no-repeat;
  background-size: 2000px 440px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item:nth-of-type(1) {
    background-size: 166.67vw 36.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item:nth-of-type(1) {
    background: url("../../public/images/features_01_sp.png") top center no-repeat;
    background-size: 100vw 139.73vw;
    height: 139.73vw;
  }
}
.features_area .features_list .features_list_item:nth-of-type(2) {
  background: url("../../public/images/features_02.png") top center no-repeat;
  background-size: 2000px 440px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item:nth-of-type(2) {
    background-size: 166.67vw 36.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item:nth-of-type(2) {
    background: url("../../public/images/features_02_sp.png") top center no-repeat;
    background-size: 100vw 144.53vw;
    height: 144.53vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item:nth-of-type(2) {
    background: url("../../public/images/features_02_sp.png") top center no-repeat;
    background-size: 100vw 144.53vw;
    height: 144.53vw;
  }
}
.features_area .features_list .features_list_item:nth-of-type(3) {
  background: url("../../public/images/features_03.png") top center no-repeat;
  background-size: 2000px 440px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item:nth-of-type(3) {
    background-size: 166.67vw 36.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item:nth-of-type(3) {
    background: url("../../public/images/features_03_sp.png") top center no-repeat;
    background-size: 100vw 155.2vw;
    height: 155.2vw;
  }
}
.features_area .features_list .features_list_item .inner {
  padding-left: 60px;
  padding-top: 124px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item .inner {
    padding-left: 5vw;
    padding-top: 10.33vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item .inner {
    padding-left: 11.33vw;
    padding-top: 18.4vw;
  }
}
.features_area .features_list .features_list_item .tit {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.02em;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item .tit {
    font-size: 2.83vw;
    line-height: 3.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item .tit {
    font-size: 5.6vw;
    line-height: 8.53vw;
  }
}
.features_area .features_list .features_list_item .tit strong {
  color: #1646dd;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.02em;
  font-weight: 700;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item .tit strong {
    font-size: 2.83vw;
    line-height: 3.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item .tit strong {
    font-size: 5.6vw;
    line-height: 8.53vw;
  }
}
.features_area .features_list .features_list_item .tit::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background: #1646dd;
  position: absolute;
  left: 0;
  bottom: -30px;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item .tit::after {
    width: 4.17vw;
    height: 0.33vw;
    bottom: -2.5vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item .tit::after {
    width: 8vw;
    height: 0.53vw;
    bottom: -5.34vw;
  }
}
.features_area .features_list .features_list_item .txt {
  margin-top: 46px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .features_area .features_list .features_list_item .txt {
    margin-top: 3.83vw;
    font-size: 1.5vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .features_area .features_list .features_list_item .txt {
    margin-top: 10vw;
    font-size: 3.73vw;
    line-height: 6.67vw;
  }
}

/*./features_area*/
/* ========================================
  merit_area
======================================== */
.merit_area {
  background: url("../../public/images/merit_bg_01.jpg") top center no-repeat, url("../../public/images/merit_bg_02.jpg") top center repeat-y;
  background-size: 2000px 480px,2000px 1px;
  padding-bottom: 154px;
}
@media screen and (max-width: 1200px) {
  .merit_area {
    background-size: 166.67vw 40vw, 166.67vw 0.08vw;
    padding-bottom: 12.83vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area {
    background: url("../../public/images/merit_bg_01_sp.jpg") top center no-repeat, url("../../public/images/merit_bg_02_sp.jpg") top center repeat-y;
    background-size: 100vw 64.4vw, 100vw 1.33vw;
    padding-bottom: 14.67vw;
  }
}
.merit_area h2 {
  padding-top: 332px;
  text-align: center;
  font-weight: 700;
  font-size: 70px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .merit_area h2 {
    padding-top: 27.67vw;
    font-size: 5.83vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area h2 {
    padding-top: 33.33vw;
    font-size: 9.33vw;
    line-height: 12vw;
  }
}
.merit_area h2 span {
  font-weight: 700;
  font-size: 80%;
  letter-spacing: 0.02em;
}
.merit_area .merit_list {
  margin-top: 181px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list {
    margin-top: 15.08vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list {
    margin-top: 18vw;
  }
}
.merit_area .merit_list .merit_list_item {
  height: 480px;
  margin-bottom: 100px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item {
    height: 40vw;
    margin-bottom: 8.33vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item {
    margin-bottom: 10.67vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(1) {
  background: url("../../public/images/merit_list_01_bg.png") top center no-repeat;
  background-size: 2000px 480px;
  padding-top: 50px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) {
    background-size: 166.67vw 40vw;
    padding-top: 4.17vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) {
    background: url("../../public/images/merit_list_01_bg_sp.png") top center no-repeat;
    background-size: 100vw 138.67vw;
    height: 138.67vw;
    padding-top: 4vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(1) .inner {
  display: flex;
  padding-left: 41px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .inner {
    padding-left: 3.42vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .inner {
    display: block;
    padding: 0;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_num {
  padding-top: 24px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_num {
    padding-top: 2vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_num {
    padding-top: 3.2vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_img {
  width: 390px;
  margin-left: 66px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_img {
    width: 32.5vw;
    margin-left: 5.5vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(1) .merit_list_img {
    width: 52vw;
    margin-top: 4.67vw;
    margin-left: 9.6vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(2) {
  background: url("../../public/images/merit_list_02_bg.png") top center no-repeat;
  background-size: 2000px 480px;
  padding-top: 68px;
  margin-bottom: 110px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) {
    background-size: 166.67vw 40vw;
    padding-top: 5.67vw;
    margin-bottom: 9.17vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) {
    background: url("../../public/images/merit_list_02_bg_sp.png") top center no-repeat;
    background-size: 100vw 136vw;
    height: 136vw;
    padding-top: 4vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(2) .inner {
  padding-left: 692px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .inner {
    padding-left: 57.67vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .inner {
    padding: 0;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(2) .merit_list_num {
  padding-top: 6px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .merit_list_num {
    padding-top: 0.5vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .merit_list_num {
    padding-top: 2.67vw;
    margin-left: 16vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .tit {
    margin-left: 16vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .txt {
    margin-left: 16vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(2) .txt strong {
  margin-left: -3px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .txt strong {
    margin-left: -0.25vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(2) .txt strong {
    margin-left: 0.4vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(3) {
  background: url("../../public/images/merit_list_03_bg.png") top center no-repeat;
  background-size: 2000px 630px;
  height: 630px;
  padding-top: 64px;
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) {
    background-size: 166.67vw 52.5vw;
    height: 52.5vw;
    padding-top: 5.33vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) {
    background: url("../../public/images/merit_list_03_bg_sp.png") top center no-repeat;
    background-size: 100vw 164vw;
    height: 164vw;
    padding-top: 4vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(3) .inner {
  padding-left: 40px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .inner {
    padding-left: 3.33vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .inner {
    padding: 0;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_num {
  padding-top: 10px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_num {
    padding-top: 0.83vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_num {
    padding-top: 3.2vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .txt {
    white-space: nowrap;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(3) .txt strong {
  margin-left: 3px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .txt strong {
    margin-left: 0.25vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .txt strong {
    margin-left: 0.4vw;
  }
}
.merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_img {
  margin-top: 37px;
  margin-left: 11px;
  width: 480px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_img {
    margin-top: 3.08vw;
    margin-left: 0.92vw;
    width: 40vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item:nth-of-type(3) .merit_list_img {
    margin-top: 6.27vw;
    margin-left: 14.93vw;
    width: 64vw;
  }
}
.merit_area .merit_list .merit_list_item .merit_list_num {
  width: 132px;
  margin-left: 8px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item .merit_list_num {
    width: 11vw;
    margin-left: 0.67vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .merit_list_num {
    width: 21.2vw;
    margin-left: 9.6vw;
  }
}
.merit_area .merit_list .merit_list_item .tit {
  display: inline-block;
  background: rgba(0, 20, 137, 0.8);
  color: #fff;
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  letter-spacing: 0.04em;
  height: 60px;
  margin-top: 22px;
  padding: 0 10px;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item .tit {
    font-size: 4.17vw;
    line-height: 4.67vw;
    height: 5vw;
    margin-top: 1.83vw;
    padding: 0 0.83vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .tit {
    font-size: 8vw;
    line-height: 9.87vw;
    height: 9.87vw;
    margin-top: 3.2vw;
    margin-left: 9.6vw;
    padding: 0 1.33vw;
  }
}
.merit_area .merit_list .merit_list_item .txt {
  margin-top: 22px;
  margin-left: 10px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item .txt {
    margin-top: 1.83vw;
    margin-left: 0.83vw;
    font-size: 1.5vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .txt {
    font-size: 3.73vw;
    line-height: 6.67vw;
    width: 81.33vw;
    margin: 3.73vw auto 0;
  }
}
.merit_area .merit_list .merit_list_item .txt strong {
  display: inline-block;
  background: rgba(0, 20, 137, 0.8);
  margin: 3px 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 0 4px;
  margin-left: -8px;
  font-weight: 700;
}
@media screen and (max-width: 1200px) {
  .merit_area .merit_list .merit_list_item .txt strong {
    margin: 0.25vw 0;
    font-size: 1.5vw;
    line-height: 2.17vw;
    padding: 0 0.33vw;
    margin-left: -0.67vw;
  }
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .txt strong {
    margin: 0.4vw 0;
    font-size: 3.73vw;
    line-height: 4.53vw;
    padding: 0 1.07vw;
    margin-left: -1.07vw;
  }
}
.merit_area .merit_list .merit_list_item .txt .sp_item {
  display: none;
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .txt .sp_item {
    display: inline-block;
  }
}
.merit_area .merit_list .merit_list_item .txt .pc_item {
  display: inline-block;
}
@media screen and (max-width: 750px) {
  .merit_area .merit_list .merit_list_item .txt .pc_item {
    display: none;
  }
}

/*./merit_area*/
/* ========================================
  flow_area
======================================== */
.flow_area {
  background: url("../../public/images/flow_bg.jpg") top center no-repeat, #88c3ef;
  background-size: 2000px 1144px,100%;
  height: 1144px;
}
@media screen and (max-width: 1200px) {
  .flow_area {
    background-size: 166.67vw 95.33vw, 100%;
    height: 95.33vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area {
    background: url("../../public/images/flow_bg_sp.jpg") top center no-repeat, #88c3ef;
    background-size: 100vw 55.2vw, 100%;
    height: auto;
    padding-bottom: 20vw;
  }
}
.flow_area .flow_list {
  display: flex;
  margin-top: 5px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list {
    margin-top: 0.42vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list {
    margin-top: 6vw;
    display: block;
  }
}
.flow_area .flow_list .flow_list_item {
  display: block;
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item {
    position: relative;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(1) {
  background: url(../../public/images/flow_list_01.png) top 163px center no-repeat;
  background-size: 255px 277px;
  width: 289px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(1) {
    background: url("../../public/images/flow_list_01.png") top 13.58vw center no-repeat;
    background-size: 21.25vw 23.08vw;
    width: 24.08vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(1) {
    background: url("../../public/images/flow_list_01.png") top 17.73vw left 30.67vw no-repeat;
    background-size: 49.07vw 53.2vw;
    width: 100%;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(2) {
  background: url(../../public/images/flow_list_02.png) top 186px left 17px no-repeat;
  background-size: 238px 237px;
  width: 257px;
  margin-left: 32px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) {
    background: url("../../public/images/flow_list_02.png") top 15.5vw left 1.42vw no-repeat;
    background-size: 19.83vw 19.75vw;
    width: 21.42vw;
    margin-left: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) {
    background: url("../../public/images/flow_list_02.png") top 17.73vw left 32vw no-repeat;
    background-size: 45.73vw 45.47vw;
    width: 100%;
    margin: 0;
    margin-top: 6.67vw;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(2) .flow_list_num {
  margin-left: 90px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) .flow_list_num {
    margin-left: 7.5vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) .flow_list_num {
    margin-left: auto;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) .flow_list_num::after {
    background: url("../../public/images/flow_line_02_sp.png") no-repeat center/100%;
    height: 74.8vw;
    bottom: -77.87vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(2) .txt {
    margin: 55.07vw 0 0 24.93vw;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(3) {
  background: url(../../public/images/flow_list_03.png) top 182px left -16px no-repeat;
  background-size: 300px 253px;
  width: 300px;
  margin-left: 29px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) {
    background: url("../../public/images/flow_list_03.png") top 15.17vw left -1.34vw no-repeat;
    background-size: 25vw 21.08vw;
    width: 25vw;
    margin-left: 2.42vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) {
    background: url("../../public/images/flow_list_03.png") top 21.73vw left 24.8vw no-repeat;
    background-size: 57.87vw 48.67vw;
    width: 100%;
    margin: 0;
    margin-top: 12.67vw;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(3) .flow_list_num {
  margin-left: 110px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) .flow_list_num {
    margin-left: 9.17vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) .flow_list_num {
    margin-left: auto;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) .flow_list_num::after {
    background: url("../../public/images/flow_line_03_sp.png") no-repeat center/100%;
    height: 86.8vw;
    bottom: -90.4vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) .tit {
    padding-top: 0vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(3) .txt {
    margin: 61.33vw 0 0 24.93vw;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(4) {
  background: url("../../public/images/flow_list_04.png") top 183px left 2px no-repeat;
  background-size: 255px 255px;
  width: 255px;
  margin-left: 29px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) {
    background: url("../../public/images/flow_list_04.png") top 15.25vw left 0.17vw no-repeat;
    background-size: 21.25vw 21.25vw;
    width: 21.25vw;
    margin-left: 2.42vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) {
    background: url("../../public/images/flow_list_04.png") top 19.07vw left 28.8vw no-repeat;
    background-size: 49.07vw 48.93vw;
    width: 100%;
    margin: 0;
    margin-top: 14.27vw;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(4) .flow_list_num {
  margin-left: 88px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) .flow_list_num {
    margin-left: 7.33vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) .flow_list_num {
    margin-left: auto;
  }
}
.flow_area .flow_list .flow_list_item:nth-of-type(4) .flow_list_num::after {
  display: none;
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) .flow_list_num::after {
    display: block;
    background: url("../../public/images/flow_line_04_sp.png") no-repeat center/100%;
    height: 76.4vw;
    bottom: -80vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) .tit {
    padding-top: 0vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_item:nth-of-type(4) .txt {
    margin: 57.33vw 0 0 24.93vw;
  }
}
.flow_area .flow_list .flow_list_num {
  margin-left: 103px;
  width: 76px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_num {
    margin-left: 8.58vw;
    width: 6.33vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_num {
    margin-left: 0;
    width: 14.67vw;
    position: absolute;
    left: 5.33vw;
    top: 0;
  }
}
.flow_area .flow_list .flow_list_num::after {
  content: "";
  display: block;
  width: 206px;
  height: 4px;
  background: url("../../public/images/flow_line.png") no-repeat center/100%;
  position: absolute;
  right: -219px;
  top: 35px;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .flow_list_num::after {
    width: 17.17vw;
    height: 0.33vw;
    right: -18.25vw;
    top: 2.92vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .flow_list_num::after {
    background: url("../../public/images/flow_line_01_sp.png") no-repeat center/100%;
    width: 1.07vw;
    height: 76.93vw;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0%);
    top: auto;
    bottom: -80vw;
  }
}
.flow_area .flow_list .tit {
  margin-top: 21px;
  color: #001489;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  height: 2.5em;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .tit {
    margin-top: 1.75vw;
    font-size: 2.17vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .tit {
    text-align: left;
    padding-top: 4.67vw;
    margin-left: 24.8vw;
    margin-top: 0vw;
    font-size: 5.6vw;
    line-height: 7.73vw;
  }
}
.flow_area .flow_list .txt {
  margin: 298px auto 0;
  width: 215px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .flow_area .flow_list .txt {
    margin: 24.83vw auto 0;
    width: 17.92vw;
    font-size: 1.5vw;
    line-height: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .flow_area .flow_list .txt {
    margin: 63.73vw 0 0 24.93vw;
    width: 65.6vw;
    font-size: 3.73vw;
    line-height: 6.67vw;
  }
}

/*./flow_area*/
/* ========================================
  support_area
======================================== */
.support_area {
  background: url("../../public/images/support_bg.jpg") top center no-repeat, #e4eff5;
  background-size: 2000px 1530px,100%;
  height: 1530px;
}
@media screen and (max-width: 1200px) {
  .support_area {
    background-size: 166.67vw 127.5vw, 100%;
    height: 127.5vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area {
    background: url("../../public/images/support_bg_sp.jpg") top center no-repeat, #e4eff5;
    background-size: 100vw 74.93vw, 100%;
    height: auto;
    padding-bottom: 10.67vw;
  }
}
.support_area .inner::after {
  content: "";
  display: block;
  width: 385px;
  height: 373px;
  background: url("../../public/images/support_deco.png") no-repeat center/100%;
  position: absolute;
  right: -134px;
  top: -44px;
}
@media screen and (max-width: 1200px) {
  .support_area .inner::after {
    width: 32.08vw;
    height: 31.08vw;
    right: -11.17vw;
    top: -3.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .inner::after {
    background: url("../../public/images/support_deco_sp.png") no-repeat center/100%;
    width: 24.93vw;
    height: 24.13vw;
    right: 6.67vw;
    top: -8.54vw;
  }
}
.support_area .support_tit {
  font-weight: 700;
  padding-top: 117px;
  font-size: 70px;
  line-height: 92px;
  letter-spacing: 0.02em;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .support_area .support_tit {
    padding-top: 9.75vw;
    font-size: 5.83vw;
    line-height: 7.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_tit {
    padding-top: 15.6vw;
    font-size: 9.33vw;
    line-height: 12.27vw;
  }
}
.support_area .support_tit span {
  font-weight: 700;
  font-size: 80%;
  line-height: 92px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .support_area .support_tit span {
    line-height: 7.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_tit span {
    line-height: 12.27vw;
  }
}
.support_area h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 92px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: -6px;
}
@media screen and (max-width: 1200px) {
  .support_area h2 {
    font-size: 5.83vw;
    line-height: 7.67vw;
    margin-top: -0.5vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area h2 {
    font-size: 9.33vw;
    line-height: 12.27vw;
    margin-top: -0.8vw;
  }
}
.support_area h2 span {
  font-weight: 700;
  font-size: 80%;
  line-height: 92px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1200px) {
  .support_area h2 span {
    line-height: 7.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area h2 span {
    line-height: 12.27vw;
  }
}
.support_area h2 strong {
  color: #1646dd;
  font-weight: 700;
}
.support_area h2 strong span {
  color: #1646dd;
}
.support_area .support_eng {
  width: 262px;
  margin: 14px auto 0;
}
@media screen and (max-width: 1200px) {
  .support_area .support_eng {
    width: 21.83vw;
    margin: 1.17vw auto 0;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_eng {
    width: 35.07vw;
    margin: 1.87vw auto 0;
  }
}
.support_area .support_list {
  display: flex;
  justify-content: space-between;
  margin-top: 148px;
}
@media screen and (max-width: 1200px) {
  .support_area .support_list {
    margin: 12.33vw auto 0;
    width: 95vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list {
    display: block;
    width: 89.33vw;
    margin: 28vw auto 0;
  }
}
.support_area .support_list_item {
  background-color: #fff;
  border-radius: 20px;
  width: 370px;
  height: 663px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .support_area .support_list_item {
    border-radius: 1.67vw;
    width: 30.83vw;
    height: 55.25vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_item {
    display: block;
    width: 89.33vw;
    margin: 0vw auto 29.33vw;
    height: 108vw;
    border-radius: 2.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_item:nth-of-type(2) {
    height: 102.67vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_item:last-of-type {
    height: 94.67vw;
    margin: 0vw auto;
  }
}
.support_area .support_list_img {
  position: absolute;
  top: -105px;
  left: 13px;
  width: 358px;
}
@media screen and (max-width: 1200px) {
  .support_area .support_list_img {
    top: -8.75vw;
    left: 2.58vw;
    width: 29.83vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_img {
    top: -20.67vw;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 61.33vw;
  }
}
.support_area .support_list_tit {
  text-align: center;
  color: #001489;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.02em;
  padding-top: 247px;
}
@media screen and (max-width: 1200px) {
  .support_area .support_list_tit {
    font-size: 2.67vw;
    line-height: 3.5vw;
    padding-top: 20.58vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_tit {
    font-size: 5.6vw;
    line-height: 8.53vw;
    padding-top: 40vw;
  }
}
.support_area .support_list_txt {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
  margin: 22px auto 0;
  width: 310px;
}
@media screen and (max-width: 1200px) {
  .support_area .support_list_txt {
    font-size: 1.5vw;
    line-height: 2.67vw;
    margin: 1.83vw auto 0;
    width: 25.83vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_list_txt {
    font-size: 3.73vw;
    line-height: 6.67vw;
    margin: 2.67vw auto 0;
    width: 73.33vw;
  }
}
.support_area .support_list_txt strong {
  font-weight: 700;
  color: #1646dd;
}
.support_area .support_read {
  background: url("../../public/images/support_read_bg.png") no-repeat center/100%;
  width: 1100px;
  height: 161px;
  margin: 70px auto;
  padding-top: 11px;
}
@media screen and (max-width: 1200px) {
  .support_area .support_read {
    width: 91.67vw;
    height: 13.42vw;
    margin: 5.83vw auto;
    padding-top: 0.92vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_read {
    background: url("../../public/images/support_read_bg_sp.png") no-repeat center/100%;
    width: 90.13vw;
    height: 33.47vw;
    margin: 9.33vw auto;
    padding-top: 2.67vw;
  }
}
.support_area .support_read_tit {
  font-weight: 700;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: 0.06em;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .support_area .support_read_tit {
    font-size: 5vw;
    line-height: 6.17vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_read_tit {
    font-size: 6.13vw;
    line-height: 8.53vw;
  }
}
.support_area .support_read_tit strong {
  color: #fff;
  background-color: #1646dd;
  font-size: 60px;
  line-height: 74px;
  margin: 0 5px;
  padding: 0 12px;
  font-weight: 700;
}
@media screen and (max-width: 1200px) {
  .support_area .support_read_tit strong {
    font-size: 5vw;
    line-height: 6.17vw;
    margin: 0 0.42vw;
    padding: 0 1vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_read_tit strong {
    font-size: 6.13vw;
    line-height: 1;
    margin: 0 0.67vw 0.67vw;
    padding: 0.53vw 1.6vw 0.53vw;
  }
}
.support_area .support_read_txt {
  margin-top: 28px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 1200px) {
  .support_area .support_read_txt {
    margin-top: 2.33vw;
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 750px) {
  .support_area .support_read_txt {
    margin-top: 2.33vw;
    font-size: 3.73vw;
    line-height: 5.87vw;
  }
}

/*./support_area*/
/* ========================================
  contact_form_area
======================================== */
/* ベースレイアウト部分 (旧case_area) */
.contact-form-area {
  background: url("../../public/images/case_bg.jpg") top center no-repeat;
  background-size: 2000px 100%;
  padding-bottom: 80px;
}
@media screen and (max-width: 1200px) {
  .contact-form-area {
    background-size: 166.67vw 100%;
    padding-bottom: 12.5vw;
  }
}
@media screen and (max-width: 750px) {
  .contact-form-area {
    background: url("../../public/images/case_bg_sp.jpg") top center no-repeat;
    background-size: 100vw 100%;
    padding-bottom: 26.67vw;
  }
}

/* フォーム部分のスタイル */
.contact-form {
  width: 100%;
  max-width: 600px;
  margin: 30px auto;
  padding: 40px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* 送信ボタン用のスタイル */
.contact-form .submit-button {
  width: 300px;
  padding: 13px;
  background-color: #F42859;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  font-weight: normal;
}

/* プライバシーポリシーリンク用のスタイル */
.contact-form .privacy-policy-link {
  color: #1646dd;
  background: none;
  border: none;
  padding: 2px 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  width: auto;
}

.contact-form .privacy-policy-link:hover {
  background-color: rgba(22, 70, 221, 0.1);
  padding: 2px 5px;
  border-radius: 4px;
  text-decoration: none;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
  font-weight: normal;
  display: block;
}

.form-group {
  margin-bottom: 25px;
}

.name-group {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
}

.name-group .form-group {
  flex: 1;
  margin-bottom: 0;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  font-size: 14px;
}

.form-group label .required {
  display: inline-block;
  background: #F42859;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 2px;
  margin-left: 8px;
  font-weight: normal;
}

.form-group label .optional {
  display: inline-block;
  background: #999999;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 2px;
  margin-left: 8px;
  font-weight: normal;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 35px;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  font-size: 14px;
  color: #999;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.privacy-policy {
  text-align: center;
  margin: 30px 0;
  font-size: 14px;
}

/* エラー表示のスタイル */
.contact-form .error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.contact-form .character-count {
  text-align: right;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

/* ボタンのスタイル */
.contact-form button {
  width: 300px;
  padding: 13px;
  background-color: #F42859;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  font-weight: normal;
}

.contact-form button:hover {
  background-color: #D11843;
  opacity: 1;
}

.contact-form button:disabled,
.contact-form button.submitting {
  background-color: #ccc;
  cursor: not-allowed;
}

/* メッセージ表示のスタイル */
.contact-form .submit-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

.contact-form .submit-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.contact-form .submit-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* レスポンシブ */
@media screen and (max-width: 768px) {
  .contact-form {
    width: 95%;
    padding: 30px 20px;
    margin: 25px auto;
  }

  .name-group {
    flex-direction: column;
    gap: 15px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    font-size: 13px;
  }

  .form-group label .optional {
    font-size: 11px;
    padding: 1px 4px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 16px;
    padding: 12px;
  }

  .form-group input::placeholder,
  .form-group textarea::placeholder {
    font-size: 13px;
  }

  .form-group textarea {
    height: 120px;
  }

  .contact-form button {
    width: 100%;
    max-width: 300px;
    padding: 12px;
  }

  .contact-form .submit-message {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
    width: 90%;
    padding: 20px 15px;
    margin: 20px auto;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group label .optional {
    font-size: 10px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 14px;
    padding: 8px 10px;
  }

  .form-group input::placeholder,
  .form-group textarea::placeholder {
    font-size: 12px;
  }

  .form-group textarea {
    height: 100px;
  }

  .privacy-policy {
    font-size: 12px;
    margin: 20px 0;
  }

  .contact-form button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .contact-form .submit-message {
    font-size: 14px;
    padding: 8px;
  }

  .contact-form .error {
    font-size: 12px;
  }

  .contact-form .character-count {
    font-size: 12px;
  }
}

/*./case_area*/
.offer_area {
  display: none;
}
@media screen and (max-width: 750px) {
  .offer_area {
    display: block;
    background: url("../../public/images/offer_bg.jpg") top center no-repeat;
    background-size: 100vw 66.4vw;
    height: 66.4vw;
    padding-top: 11.07vw;
  }
}
.offer_area .offer {
  position: relative;
  background-color: #fff;
}
@media screen and (max-width: 750px) {
  .offer_area .offer {
    margin: 0 auto;
    width: 89.33vw;
    height: 44vw;
    border-radius: 1.33vw;
    padding-top: 5.73vw;
    filter: drop-shadow(1.33vw 1.33vw 1.33vw rgba(0, 0, 0, 0.1));
    display: block;
  }
}
.offer_area .offer::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
}
@media screen and (max-width: 750px) {
  .offer_area .offer::after {
    background: url("../../public/images/offer_deco.png") no-repeat center/100%;
    width: 15.07vw;
    height: 19.47vw;
    top: -7.6vw;
  }
}
.offer_area .offer_tit {
  color: #1646dd;
  font-weight: 700;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 750px) {
  .offer_area .offer_tit {
    text-align: right;
    font-size: 4.27vw;
    padding-right: 5.73vw;
  }
}
.offer_area .offer_txt {
  letter-spacing: 0.02em;
}
@media screen and (max-width: 750px) {
  .offer_area .offer_txt {
    text-align: center;
    margin-top: 1.73vw;
    font-size: 3.47vw;
    line-height: 6.67vw;
  }
}
.offer_area .btn {
  display: block;
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 750px) {
  .offer_area .btn {
    background: url("../../public/images/offer_btn.png") no-repeat center/100%;
    width: 77.33vw;
    height: 16.13vw;
    font-size: 4vw;
    line-height: 16.13vw;
    padding-right: 2.67vw;
    margin: 2.4vw auto 0;
  }
}

/* ========================================
  footer
======================================== */
footer small {font-size: 100%;}

footer {
	font-size: 0.7rem;
	text-align: center;
	padding: 20px;
	background: #fff;
	color: #111;
  filter: drop-shadow(0px -5px 5px rgba(0, 0, 0, 0.15));
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*リンクテキスト*/
footer a {text-decoration: none;color: #111;}

/*著作部分*/
footer .pr {display: block;}


/*# sourceMappingURL=style.css.map */
